import * as React from "react";
import { createFileRoute, Outlet } from "@tanstack/react-router";
import Header from "../Components/header";
import PromoBanner from "../Components/PromoBanner";

export const Route = createFileRoute("/_rootLayout")({
	component: RouteComponent,
});

function RouteComponent() {
	return (
		<div>
			<PromoBanner />
			<Header />
			<hr />
			<Outlet />
		</div>
	);
}
