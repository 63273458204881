import { Link } from "@tanstack/react-router";
import NavanLogo from "../assets/NavanLogoSmall.svg";
import GlobeIcon from "../assets/globe.svg";
import { Navigation } from "./Navigation";
export default function Header() {
	return (
		<header className="flex min-h-20 flex-col justify-center">
			<div className="flex items-center justify-between px-10">
				<div>
					<Link to="/">
						<NavanLogo />
					</Link>
				</div>
				<div>
					<Navigation />
				</div>
				<div className="flex items-center gap-2">
					<button
						type="button"
						className=" min-w-[160px] rounded-full border-2 border-[#1d0234] bg-[#1d0234] px-4.5 py-[10px] font-extralight text-sm text-white transition-colors hover:border-purple-900 hover:bg-purple-900 md:min-w-0 md:px-7"
					>
						Get Started
					</button>
					<button
						type="button"
						className=" min-w-[160px] rounded-full border-2 border-purple-800 bg-purple-800 px-4.5 py-[10px] font-extralight text-sm text-white transition-colors hover:border-purple-900 hover:bg-purple-900 md:min-w-0 md:px-7 "
					>
						Request Demo
					</button>
					<div className="flex gap-3">
						<Link to="/auth" className="font-semibold text-sm">
							Log In
						</Link>
						<div>
							<GlobeIcon />
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}
