import { createFileRoute, Link } from "@tanstack/react-router";
import BgImage from "../assets/index-bg.svg";

export const Route = createFileRoute("/_rootLayout/")({
	component: RouteComponent,
});
const imageSvgs = {
	airplane:
		"https://res.cloudinary.com/tripactions/image/upload/v1705926269/site/sign-up/selection-hero/cards/icons/company-travel.svg",
	creditCard:
		"https://res.cloudinary.com/tripactions/image/upload/v1705926269/site/sign-up/selection-hero/cards/icons/expense.svg",
};

function RouteComponent() {
	return (
		<div>
			<div className="relative flex w-full">
				<div className="-z-10 absolute">
					<BgImage />
				</div>
				<div className="flex w-1/2 justify-center p-5">
					<div className="flex h-full max-w-[550px] flex-col gap-6">
						<img
							className="w-1/2 opacity-40"
							alt="review"
							src="https://res.cloudinary.com/tripactions/image/upload/f_auto/c_limit/w_768/q_auto/f_auto/q_auto/v1720355641/site/homepage-2024/g2-rating-black.png"
						/>
						<h2 className="font-[Sanomat] text-5xl ">
							Business travel & expense management. Solved.
						</h2>
						<h3 className="text-xl">
							What would you like to do with Navan Today?
						</h3>
						<div className="flex flex-col">
							<div className="flex gap-2">
								<CheckCard
									svg="airplane"
									text="Create a company travel program"
								/>
								<CheckCard
									svg="creditCard"
									text="Manage company cards & expenses"
								/>
							</div>
							<div className="flex gap-2 pt-4">
								<CheckCard size="sm" text="Arrange a team offsite" />
								<CheckCard size="sm" text="Book trips for employees & execs" />
								<CheckCard size="sm" text="Book your own work trip" />
							</div>
						</div>
						<div className="mt-8 flex w-1/2 flex-col items-start gap-3">
							<button
								type="button"
								className=" w-full min-w-[260px] rounded-full border-2 border-[#1d0234] bg-[#1d0234] px-12 py-[10px] font-extralight text-sm text-white transition-colors hover:border-purple-900 hover:bg-purple-900 md:min-w-0 md:px-7"
							>
								Get started
							</button>
							<a
								href="https://docs.zephyr-cloud.io/general/why-zephyr-cloud"
								className="w-full text-center underline"
							>
								Watch a demo video
							</a>
						</div>
					</div>
				</div>
				<div className="w-1/2 p-5">
					<video
						src="https://res.cloudinary.com/tripactions/video/upload/f_auto/q_auto/v1721222062/site/homepage-2024/desktop_1920_1440_atf_animation_2.mp4"
						autoPlay
						loop
						muted
					/>
				</div>
			</div>
		</div>
	);
}

const CheckCard = ({
	svg,
	text,
	size = "lg",
}: {
	svg?: keyof typeof imageSvgs;
	text: string;
	size?: "sm" | "lg";
}) => {
	return (
		<div
			className={`relative flex items-center justify-center rounded-lg ${size === "lg" ? "bg-[#e9e7fb]" : "bg-[#d1d0fa]"} p-5 transition-all duration-300 hover:scale-105`}
		>
			<input
				className={`absolute top-1 text-blue-800 ${size === "lg" ? "left-1" : "-translate-x-1/2 left-1/2 transform"}`}
				type="checkbox"
			/>
			{/* <input
				type="checkbox"
				className={`absolute top-1 h-6 w-6 cursor-pointer rounded-lg border-2 border-transparent checked:border-blue-900 checked:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-900 ${size === "lg" ? "left-1" : "-translate-x-1/2 left-1/2 transform"}`}
			/> */}

			<div className="flex w-3/4 flex-col items-center justify-center">
				{svg && <img className="w-1/4" alt="card icon" src={imageSvgs[svg]} />}
				<h5
					className={`text-center font-thin ${size === "lg" ? "text-lg" : "text-sm"}`}
				>
					{text}
				</h5>
			</div>
		</div>
	);
};
