/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as RootLayoutImport } from './routes/_rootLayout'
import { Route as AuthLayoutImport } from './routes/_authLayout'
import { Route as RootLayoutIndexImport } from './routes/_rootLayout.index'
import { Route as AuthLayoutAuthImport } from './routes/_authLayout.auth'

// Create/Update Routes

const RootLayoutRoute = RootLayoutImport.update({
  id: '/_rootLayout',
  getParentRoute: () => rootRoute,
} as any)

const AuthLayoutRoute = AuthLayoutImport.update({
  id: '/_authLayout',
  getParentRoute: () => rootRoute,
} as any)

const RootLayoutIndexRoute = RootLayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => RootLayoutRoute,
} as any)

const AuthLayoutAuthRoute = AuthLayoutAuthImport.update({
  id: '/auth',
  path: '/auth',
  getParentRoute: () => AuthLayoutRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authLayout': {
      id: '/_authLayout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthLayoutImport
      parentRoute: typeof rootRoute
    }
    '/_rootLayout': {
      id: '/_rootLayout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof RootLayoutImport
      parentRoute: typeof rootRoute
    }
    '/_authLayout/auth': {
      id: '/_authLayout/auth'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof AuthLayoutAuthImport
      parentRoute: typeof AuthLayoutImport
    }
    '/_rootLayout/': {
      id: '/_rootLayout/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof RootLayoutIndexImport
      parentRoute: typeof RootLayoutImport
    }
  }
}

// Create and export the route tree

interface AuthLayoutRouteChildren {
  AuthLayoutAuthRoute: typeof AuthLayoutAuthRoute
}

const AuthLayoutRouteChildren: AuthLayoutRouteChildren = {
  AuthLayoutAuthRoute: AuthLayoutAuthRoute,
}

const AuthLayoutRouteWithChildren = AuthLayoutRoute._addFileChildren(
  AuthLayoutRouteChildren,
)

interface RootLayoutRouteChildren {
  RootLayoutIndexRoute: typeof RootLayoutIndexRoute
}

const RootLayoutRouteChildren: RootLayoutRouteChildren = {
  RootLayoutIndexRoute: RootLayoutIndexRoute,
}

const RootLayoutRouteWithChildren = RootLayoutRoute._addFileChildren(
  RootLayoutRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof RootLayoutRouteWithChildren
  '/auth': typeof AuthLayoutAuthRoute
  '/': typeof RootLayoutIndexRoute
}

export interface FileRoutesByTo {
  '': typeof AuthLayoutRouteWithChildren
  '/auth': typeof AuthLayoutAuthRoute
  '/': typeof RootLayoutIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authLayout': typeof AuthLayoutRouteWithChildren
  '/_rootLayout': typeof RootLayoutRouteWithChildren
  '/_authLayout/auth': typeof AuthLayoutAuthRoute
  '/_rootLayout/': typeof RootLayoutIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths: '' | '/auth' | '/'
  fileRoutesByTo: FileRoutesByTo
  to: '' | '/auth' | '/'
  id:
    | '__root__'
    | '/_authLayout'
    | '/_rootLayout'
    | '/_authLayout/auth'
    | '/_rootLayout/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthLayoutRoute: typeof AuthLayoutRouteWithChildren
  RootLayoutRoute: typeof RootLayoutRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthLayoutRoute: AuthLayoutRouteWithChildren,
  RootLayoutRoute: RootLayoutRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authLayout",
        "/_rootLayout"
      ]
    },
    "/_authLayout": {
      "filePath": "_authLayout.tsx",
      "children": [
        "/_authLayout/auth"
      ]
    },
    "/_rootLayout": {
      "filePath": "_rootLayout.tsx",
      "children": [
        "/_rootLayout/"
      ]
    },
    "/_authLayout/auth": {
      "filePath": "_authLayout.auth.tsx",
      "parent": "/_authLayout"
    },
    "/_rootLayout/": {
      "filePath": "_rootLayout.index.tsx",
      "parent": "/_rootLayout"
    }
  }
}
ROUTE_MANIFEST_END */
