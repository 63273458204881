import React from "react";
import GiftIcon from "../assets/gift.svg";

const PromoBanner = () => {
	return (
		<div className="flex w-full items-center justify-center gap-1 bg-gradient-to-r bg-pos-100 from-purple-600 to-indigo-600 px-4 py-2 text-white transition-all duration-1000 hover:bg-pos-0">
			<div className="flex items-center space-x-2">
				{/* Icon (if needed, replace with actual icon component or image) */}
				<GiftIcon />

				<span>
					Get <strong>$500</strong> for your next vacation when your company
					books a hotel on Nevan.
				</span>
			</div>
			<span>|</span>
			<a href="/" className="font-semibold underline">
				Learn more
			</a>
		</div>
	);
};

export default PromoBanner;
