import * as React from "react";
import { createFileRoute } from "@tanstack/react-router";
import AngularComponentWrapper from "../AngularWrapper";

export const Route = createFileRoute("/_authLayout/auth")({
	component: RouteComponent,
});

function RouteComponent() {
	return <AngularComponentWrapper />;
}
