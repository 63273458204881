import { Link } from "@tanstack/react-router";

export function Navigation() {
	return (
		<nav>
			<ul className="flex gap-5 text-gray-800">
				<Products />
				<Solutions />
				<Pricing />
				<Resources />
				<Company />
			</ul>
		</nav>
	);
}

function Products() {
	return (
		<li className="mx-4">
			<h3>Products</h3>
		</li>
	);
}
function Solutions() {
	return (
		<li className="mx-4">
			<h3>Solutions</h3>
		</li>
	);
}
function Pricing() {
	return (
		<li className="mx-4">
			<h3>Pricing</h3>
		</li>
	);
}
function Resources() {
	return (
		<li className="mx-4">
			<h3>Resources</h3>
		</li>
	);
}
function Company() {
	return (
		<li className="mx-4">
			<h3>Company</h3>
		</li>
	);
}
